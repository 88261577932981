import React from 'react'

const LoadingAnimation = props => 
  <div style={{transform: `scale(${props.scale * .8 || 1})`}}>
    <div class="loadingio-spinner-bricks-j9tf1kgh20l"><div class="ldio-2fauo4njz0c">
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
    </div>
    </div>
  </div>

export default LoadingAnimation