import React, { Component } from 'react'
import { connect } from 'react-redux'

import { changeLocation, changeTheater, changeReservedSeating, changeFormat, changeDate, changeMinTime, changeMaxTime } from '../actions/filters'
import createFilterOptions from "react-select-fast-filter-options";

import Select, {components} from 'react-select'
import Async from 'react-select/lib/Async';
import AsyncSelect from 'react-select/lib/Async';

import AsyncAutocomplete from '../components/AsyncAutocomplete'
import DateSelect from '../components/DateSelect'
import TheaterSelect from '../components/TheaterSelect'
import ReservedSelect from '../components/ReservedSelect'
import FormatSelect from '../components/FormatSelect'
import TimeSelect from '../components/TimeSelect'


class Filters extends Component {
  constructor(props) {
    super(props)

    this.state = {filtersPressed: false, width: 0, height: 0,}
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  handleLocationChange = selectedOption => {
    this.props.changeLocation(selectedOption.value)
  }

  handleDateChange = selectedOption => {
    this.props.changeDate(selectedOption.value)
  }

  handleTheaterChange = selectedOption => {
    this.props.changeTheater(selectedOption.value)
  }

  handleReservedSeatingChange = selectedOption => {
    this.props.changeReservedSeating(selectedOption.value);
  }
 
  handleFormatChange = selectedOption => {
    this.props.changeFormat(selectedOption.value)
  }

  handleMinTimeChange = selectedOption => {
    this.props.changeMinTime(selectedOption.value)
  }

  handleMaxTimeChange = selectedOption => {
    this.props.changeMaxTime(selectedOption.value)
  }

  handleFiltersTap = event => {
    event.preventDefault()
    this.setState({filtersPressed: !this.state.filtersPressed})
  }

  render() {
    const { location, date, theater, reservedSeating, format, minTime, maxTime, selectedMovieId} = this.props.filters
    const filtersToggled = this.state.width > 768 || this.state.filtersPressed //|| (selectedMovieId ? true : false)
    var body;
    if (this.props.display == "mobile") {
      body = <div className='filters-navbar'>
        <div id='filters-main'>
        <div id='logo-container' onClick={this.props.handleLogoClick}>
          <img id='logo' src='/seats-light.png'/>
          <h2>CinemaStack</h2>
        </div>
        <img src="profile-chair.png" id="profile-pic" onClick={this.props.handleAuthClick}/>
        <AsyncAutocomplete 
          onChange={this.handleLocationChange}
          currentLocation={location}
        />
        <DateSelect 
          date={date}
          handleDateChange={this.handleDateChange}
        />
        { this.state.width <= 768 ?
        <div onClick={this.handleFiltersTap} >
        <img id="filters-icon" src={ this.state.filtersPressed ? "/sliders-teal.png" : "/sliders-gray.png"} />
        </div>
        : null
        }
        </div>
        { filtersToggled ?
        <ReservedSelect
          reservedSeating={reservedSeating}
          handleReservedSeatingChange={this.handleReservedSeatingChange}
        /> : null
        }

        { filtersToggled ?
        <TimeSelect
          placeholder={'From'}
          time={this.minTime}
          handleTimeChange={this.handleMinTimeChange}
        /> : null
        }

        { filtersToggled ?
        <TimeSelect
          placeholder={'To'}
          time={this.maxTime}
          handleTimeChange={this.handleMaxTimeChange}
        /> : null
        }

        
        { filtersToggled ? 
        <TheaterSelect
          theater={theater}
          showings={this.props.showings}
          handleTheaterChange={this.handleTheaterChange}
        /> : null
        }

        { filtersToggled ?
        <FormatSelect
          format={this.format}
          handleFormatChange={this.handleFormatChange}
        /> : null
        }
      </div>
    } else if (this.props.display == "tablet") {
      body =
      <div className='filters-navbar'>
        <div id='filters-main' style={{width: '100vw'}}>
        <div id='logo-container' onClick={this.props.handleLogoClick}>
          <img id='logo' src='/seats-light.png'/>
          <h2>CinemaStack</h2>
        </div>
          <img src="profile-chair.png" id="profile-pic" onClick={this.props.handleAuthClick}/>
        </div>
        <AsyncAutocomplete 
          onChange={this.handleLocationChange}
          currentLocation={location}
        />
        <DateSelect 
          date={date}
          handleDateChange={this.handleDateChange}
        />
        <ReservedSelect
          reservedSeating={reservedSeating}
          handleReservedSeatingChange={this.handleReservedSeatingChange}
        /> 

        <TimeSelect
          placeholder={'From'}
          time={this.minTime}
          handleTimeChange={this.handleMinTimeChange}
        /> 


        <TimeSelect
          placeholder={'To'}
          time={this.maxTime}
          handleTimeChange={this.handleMaxTimeChange}
        /> 

        
 
        <TheaterSelect
          theater={theater}
          showings={this.props.showings}
          handleTheaterChange={this.handleTheaterChange}
        /> 


        <FormatSelect
          format={this.format}
          handleFormatChange={this.handleFormatChange}
        /> 
      </div>
    } else {
      body =
      <div className='filters-navbar'>
        <div id='filters-main'>
        <div id='logo-container' onClick={this.props.handleLogoClick}>
          <img id='logo' src='/seats-light.png'/>
          <h2>CinemaStack</h2>
        </div>
        <AsyncAutocomplete 
          onChange={this.handleLocationChange}
          currentLocation={location}
        />
        <DateSelect 
          date={date}
          handleDateChange={this.handleDateChange}
        />
        { this.state.width <= 768 ?
        <div onClick={this.handleFiltersTap} >
        <img id="filters-icon" src={ this.state.filtersPressed ? "/sliders-teal.png" : "/sliders-gray.png"} />
        </div>
        : null
        }
        </div>
        { filtersToggled ?
        <ReservedSelect
          reservedSeating={reservedSeating}
          handleReservedSeatingChange={this.handleReservedSeatingChange}
        /> : null
        }

        { filtersToggled ?
        <TimeSelect
          placeholder={'From'}
          time={this.minTime}
          handleTimeChange={this.handleMinTimeChange}
        /> : null
        }

        { filtersToggled ?
        <TimeSelect
          placeholder={'To'}
          time={this.maxTime}
          handleTimeChange={this.handleMaxTimeChange}
        /> : null
        }

        
        { filtersToggled ? 
        <TheaterSelect
          theater={theater}
          showings={this.props.showings}
          handleTheaterChange={this.handleTheaterChange}
        /> : null
        }

        { filtersToggled ?
        <FormatSelect
          format={this.format}
          handleFormatChange={this.handleFormatChange}
        /> : null
        }
        <img src="profile-chair.png" id="profile-pic" onClick={this.props.handleAuthClick}/>
      </div>
    }

    return (
      <div>
        {body}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showings: state.showings,
    filters: state.filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLocation: location => dispatch(changeLocation(location)),
    changeTheater: theater => dispatch(changeTheater(theater)),
    changeReservedSeating: reservedSeating => dispatch(changeReservedSeating(reservedSeating)),
    changeFormat: format => dispatch(changeFormat(format)),
    changeDate: date => dispatch(changeDate(date)),
    changeMinTime: minTime => dispatch(changeMinTime(minTime)),
    changeMaxTime: maxTime => dispatch(changeMaxTime(maxTime))
  }
}
export default connect(mapStateToProps ,mapDispatchToProps)(Filters)

