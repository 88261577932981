const initialState = {
  fetchingShowings: false, 
  fetchingMaps: false
}

const fetching = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_SHOWINGS':
      return Object.assign({}, state, {fetchingShowings: true,  fetchShowingsFailed: false})
    case 'SHOWINGS_FETCHED':
      return Object.assign({}, state, {fetchingShowings: false})
    case 'FETCHING_MAPS':
      return Object.assign({}, state, {fetchingMaps: true})
    case 'MAPS_FETCHED':
      return Object.assign({}, state, {fetchingMaps: false})
    case 'FETCH_SHOWINGS_FAILED':
      return Object.assign({}, state, {fetchShowingsFailed: true})
    default:
      return state
  }
}

export default fetching