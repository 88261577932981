import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select'
import selectStyle from '../style/selectStyle'

type State = {
  inputValue: string,
};

const DropdownIndicator = props => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <div><i className="fa fa-search"></i></div>
    </components.DropdownIndicator>
  );
};

const defaultOptions = [{value: '98144', label: '98144'}]

const locationOptions = require('../data/locationOptions')

const properCase  = (location) => {
  if (location.includes(',')) {
    const s = location.split(',')
    const city = s[0]
    const state = s[1]
    const properCity=  city.split('+').reduce((acc, word) => {
      return acc += word.charAt(0).toUpperCase() + word.slice(1) + ' '
    }, '').slice(0,-1)
    return `${properCity}, ${state.toUpperCase()}`
  } else {
    return location
  }

}

const loadOptions = (inputValue, callback) => {
  if(inputValue && inputValue.length > 2) {
    const len = inputValue.length
    const alpha = locationOptions[inputValue.charAt(0).toUpperCase()]
    const options = alpha.filter(e => e.label.toLowerCase().includes(inputValue.toLowerCase())).slice(0,15)
    callback(options)
  }
};

export default class WithCallbacks extends Component<*, State> {  state = { inputValue: '' };
  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
    return inputValue;
  };
  render() {
    return (
      <div>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onInputChange={this.handleInputChange}
          styles={selectStyle(175)}
          components={{ DropdownIndicator }}
          onChange={this.props.onChange}
          placeholder='City, State or Zip'
          noOptionsMessage={() => "Zipcode or City, State"}
          loadingMessage={() => "Zipcode or City, State"}
          value={{value: this.props.currentLocation, label: properCase(this.props.currentLocation)}}
        />
      </div>
    );
  }
}