import React, {Component} from 'react'
import classNames from 'classnames'

class SeatMap extends Component {
	constructor(props) {
		super(props)

		this.state = {incrementedVisibleSeatMaps: false}
	}

	componentDidUpdate() {
		if (this.state.incrementedVisibleSeatMaps == false && this.props.divs != "null") {
			this.setState({incrementedVisibleSeatMaps: true})
			this.props.incrementVisibleSeatMaps()
		}
	}

	render() {
		return (
			<div className='seat-map' style={{height: 300 * this.props.sizeMultiplier + 'px', width: 300 * this.props.sizeMultiplier + 'px', transform: `scale(${this.props.scale})`}}>
		      {this.props.divs.map((div, i) => {
		      	var classes = null
		      	if (div.class != null) {
		      		const rawClasses = div.class.split(" ")
					classes = classNames(rawClasses[0], rawClasses[1], rawClasses[2])
				}
		        return (<div id={div.id} className={classes || div.class} key={i} style={{left: div.left, top: div.top, height: div.height, width: div.width}}></div>)
		      })}
		    </div>
		)
	}
}

export default SeatMap