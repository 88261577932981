import React from 'react'

const availableSeatStyle = {
  display: 'inline-block',
  borderRadius: '3px', 
  backgroundColor: '#33FFFC', 
  height: '10px', 
  width: '10px',
  marginLeft: '5px'
}

const accessibleSeatStyle = {
  display: 'inline-block',
  borderRadius: '3px', 
  backgroundColor: '#4CCAFA', 
  height: '10px', 
  width: '10px',
  marginLeft: '5px'
}

const reservedSeatStyle = {
  display: 'inline-block',
  borderRadius: '3px', 
  backgroundColor: '#808080', 
  height: '10px', 
  width: '10px',
  marginLeft: '5px'
}

const spanStyle = {
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: 'lighter',
  color: 'white',
  marginLeft: '5px'
}

const Legend = () =>
  <div id="legend">
    <div>
    <div style={availableSeatStyle}></div>
    <span style={spanStyle}>Available</span>
    </div>
    <div>
    <div style={accessibleSeatStyle}></div>
    <span style={spanStyle}>Accessible</span>
    </div>
    <div>
    <div style={reservedSeatStyle}></div>
    <span style={spanStyle}>Unavailable</span>
    </div>
  </div>

export default Legend