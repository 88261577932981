import React, {Component} from 'react'
import { connect } from 'react-redux'
import SeatMap from './SeatMap'
import AnimatedSeatMap from './AnimatedSeatMap'
import LoadingIndicator from './LoadingIndicator'
import LoadingAnimation from './LoadingAnimation'
import { fetchMaps } from '../actions/showings'
import TrackVisibility from 'react-on-screen'

class ShowingCard extends Component {

  constructor(props) {
    super(props)

    this.state = {retryCount: 0, fetching: false, timedOut: false}
    this.handleReloadClick = this.handleReloadClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showing.id !== this.props.showing.id) {
      this.setState({retryCount: 0, fetching: false, timedOut: false})
    }

    if (this.state.timedOut == true && this.props.showing.seatMap !== "null") {
      this.setState({timedOut: false})
    }

    if (this.state.fetching === false && this.props.showing.seatMap == "null" && this.props.isVisible == true) {
      this.setState({fetching: true})
      this.props.fetchMaps([this.props.showing])
      // setTimeout(() => this.timeout(), 30000)
    }
  }

  timeout() {
    if (this.props.showing.seatMap == "null") {
      this.setState({timedOut: true})
    }
  }

  retry() {
    if (this.props.showing.seatMap == "null") {
      this.setState({retryCount: this.state.retryCount + 1})
      if (this.state.retryCount < 2) {
        this.props.fetchMaps([this.props.showing])
      }
    }
  }

  handleReloadClick(showing, event) {
    event.preventDefault()
    this.props.fetchMaps([showing])
    // console.log("handleReloadClick")
    this.setState({timedOut: false})
    // this.retry()
  }

  num(string) {
    return string.replace('%', '')
  }

  removeDivPadding(divs){
    let averageWidth = 0
    let widthCount = 0
    divs.forEach(div => {
      averageWidth += parseFloat(div.width)
      widthCount++
    })
    averageWidth = averageWidth / widthCount

    let minimumLeft = 100
    divs.forEach(div => {
      if (parseFloat(div.left) < minimumLeft) minimumLeft = parseFloat(div.left)
    })

    let maximumRight = 0
    divs.forEach(div => {
      if (parseFloat(div.right) > maximumRight) maximumRight = parseFloat(div.right)
    })
    maximumRight = 100 - (maximumRight + averageWidth)

    let minimumTop = 100
    divs.forEach(div => {
      if (parseFloat(div.top) < minimumTop) minimumTop = parseFloat(div.top)
    })

    let maximumTop = 0
    divs.forEach(div => {
      if (parseFloat(div.top) > maximumTop) maximumTop = parseFloat(div.top)
    })
    maximumTop = 100 - (maximumTop + averageWidth) 

    const spaces = [minimumLeft, maximumRight, minimumTop, maximumTop]
    let minimumSpace = 100
    spaces.forEach(space => {
      if (space < minimumSpace) minimumSpace = space
    })

    let scale = 100 / (100 - (minimumSpace * 1.75))

    return scale

    const minLeft = divs.reduce((a, b) => parseFloat(a.left) < parseFloat(b.left) ? parseFloat(a.left) : parseFloat(b.left))
    const minTop = divs.reduce((a, b) => parseFloat(a.top) < parseFloat(b.top) ? parseFloat(a.top) : parseFloat(b.top))
    divs.forEach(div => {
      if (parseFloat(div.left) <= 50) {
        div.left = (parseFloat(div.left) - (minimumLeft / 2)).toString() + "%"
      } else {
        div.left = (parseFloat(div.left) + (maximumRight / 2)).toString() + "%"
      }

      // div.left = (parseFloat(div.left) - (minimumLeft - minimumRight)).toString() + "%"

      // div.left = (parseFloat(div.left) - minimumLeft) + (((averageWidth * .01) * parseFloat(div.left)) * 2) + "%"
      div.top = (parseFloat(div.top) - minimumTop).toString() + "%"
      div.width = parseFloat(div.width) + ((averageWidth * .01) * parseFloat(div.width)) + "%"
    })
    return divs
  }

  render() {
    const { showing, mobile, screenWidth } = this.props

    const hasToken = showing.token !== undefined

    let sizeMultiplier = this.props.sizeMultiplier

    if (mobile == true) {
      if (sizeMultiplier == 1) {sizeMultiplier = .33}
      if (sizeMultiplier == 2) {sizeMultiplier = 0.5}
      if (sizeMultiplier == 3) {sizeMultiplier = 1}
      // switch(sizeMultiplier) {
      //   case 1:
      //     sizeMultiplier = 1
      //   case 2:
      //     sizeMultiplier = .5
      //   case 3:
      //     sizeMultiplier = .33
      //   default:
      //     sizeMultiplier = sizeMultiplier
      // }
    }

    return (
      <div 
      className="showing-card" 
      // style={{cursor:'pointer', height: this.props.sizeMultiplier < .33 ? 370 * this.props.sizeMultiplier + 'px' : '', width: 330 * this.props.sizeMultiplier + 'px'}}
      style={{cursor: !this.state.timedOut ? 'pointer' : '', height: (mobile ? screenWidth * 1.12121212 : 370 ) * (sizeMultiplier < .35 ? (sizeMultiplier * 1.5) : sizeMultiplier) + 'px', width: (mobile ? screenWidth : 330) * sizeMultiplier + 'px'}}
      onClick={hasToken && !this.state.timedOut ? ()=> window.open('https://us-central1-focused-outlook-225006.cloudfunctions.net/redirect?token=' + encodeURIComponent(this.props.showing.token), "_blank") : null}
      >
        <div className="showing-metadata" style={{cursor:'pointer'}} onClick={hasToken ? ()=> window.open('https://us-central1-focused-outlook-225006.cloudfunctions.net/redirect?token=' + encodeURIComponent(this.props.showing.token), "_blank") : null}>
          {showing.theaterName} <b>{showing.time}</b> {' '} {'' + showing.format + ''}
        </div>
        { showing.seatMap === 'failed' ? <div className="showing-loading-gif-container">  <img style={{cursor: 'pointer', height: "50px", width: "50px"}} src="reload-icon.png" onClick={this.handleReloadClick.bind(showing, null)} /> </div> : ( showing.reservedSeating === true ? (showing.seatMap != 'null' ? 
        <TrackVisibility once partialVisibility>
          <SeatMap
            sizeMultiplier={sizeMultiplier}
            scale={this.removeDivPadding(showing.seatMap)}
            divs={showing.seatMap}
            incrementVisibleSeatMaps={this.props.incrementVisibleSeatMaps}
          /> 
        </TrackVisibility> 
        : <div className="showing-loading-gif-container">  <LoadingAnimation key={this.props.key} scale={sizeMultiplier} /> </div> ) :
        <img className="general-admission" /> )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMaps: showingsByMovie => dispatch(fetchMaps(showingsByMovie))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowingCard)


