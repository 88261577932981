import React from 'react'
import Select from 'react-select'
import selectStyle from '../style/selectStyle'

const reservationOptions = [
  {value: 'true', label: 'Reserved'},
  {value: 'false', label: 'All Seating'}
]

const ReservedSelect = props =>
  <Select
    options={reservationOptions}
    value={reservationOptions.find(e => props.reservedSeating === e.value)}
    onChange={props.handleReservedSeatingChange}
    styles={selectStyle(120)}
    placeholder='Seating'
    isSearchable={false}
  />

export default ReservedSelect