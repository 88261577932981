import React from 'react'
import classNames from 'classnames'
import LoadingAnimation from './LoadingAnimation'
import firebase from 'firebase'
import CheckoutForm from './CheckoutForm';

class AuthCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {tab: 'login', email: '', password: '', passwordConfirmation: '', confirmationCode: '', secondaryTab: '', authMessage: null, loading: false, emailEnabled: true, prepopulatedEmail: '', userEmail: ''}

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handlePasswordConfirmationChange = this.handlePasswordConfirmationChange.bind(this)
    this.handleConfirmationCodeChange = this.handleConfirmationCodeChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleRegisterClick = this.handleRegisterClick.bind(this)
    this.handlePasswordChangeClick = this.handlePasswordChangeClick.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signUp = this.signUp.bind(this)
    this.signOut = this.signOut.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.sendPasswordReset = this.sendPasswordReset.bind(this)
  }

  componentDidMount() {
    if (this.props.prepopulatedEmail) {
      this.setState({tab: 'register', 'secondaryTab': '', prepopulatedEmail: this.props.prepopulatedEmail})
    }
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handlePasswordConfirmationChange(event) {
    this.setState({passwordConfirmation: event.target.value});
  }

  handleConfirmationCodeChange(event) {
    this.setState({confirmationCode: event.target.value});
  }

  async handlePasswordChangeClick(event) {
    this.setState({tab: "passwordChange", secondaryTab: "", "authMessage": "Change Password", password: "", passwordConfirmation: ""})
  }

  async updatePassword() {

    this.setState({loading: true})

    const password = this.state.password
    const passwordConfirmation = this.state.passwordConfirmation

    if (password != passwordConfirmation) {
      this.setState({authMessage: "Passwords do not match.", loading: false})
      return
    }

    if (password.length < 6) {
      this.setState({authMessage: "Password must be at least 6 characters.", loading: false})
      return
    }

    const user = await firebase.auth().currentUser

    user.updatePassword(password).then(() => {
      this.setState({authMessage: "Password changed successfully.", loading: false})
    }).catch((error) => {
      this.setState({authMessage: error.message, loading: false})
    })

  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  handleRegisterClick(event) {
    event.preventDefault()
    this.setState({tab: 'register'})
    this.setState({password: '', passwordConfirmation: ''})
  }

  handleLoginClick(event) {
    event.preventDefault()
    this.setState({tab: 'login'})
    this.setState({password: '', passwordConfirmation: ''})
  }

  sendPasswordReset() {
  if (this.state.email == "") {
    this.setState({authMessage: "Please input your email address below and try again."})
    return
  }

  firebase.auth().sendPasswordResetEmail(this.state.email)
    .then(() => {
      this.setState({authMessage: "Password reset email sent."})
    })
    .catch((error) => {
      this.setState({authMessage: error.message})
    });
  }

  async signIn() {
    this.setState({loading: true})
    const email = this.state.email
    const password = this.state.password
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        var user = userCredential.user;
        this.setState({authMessage: "", loading: false})
      })
      .catch((error) => {
        this.setState({loading: false, authMessage: error.message})
    });
  }

  async signUp() {
    this.setState({loading: true})

    const email = this.state.email
    const password = this.state.password
    const passwordConfirmation = this.state.passwordConfirmation

    if (password != passwordConfirmation) {
      this.setState({authMessage: "Passwords do not match.", loading: false})
      return
    }

    if (password.length < 6) {
      this.setState({authMessage: "Password must be at least 6 characters.", loading: false})
      return
    }

    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      var user = userCredential.user;
      this.setState({loading: false})
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setState({authMessage: errorMessage, loading: false})
    });
  }

  async signOut() {
    this.setState({loading: true})
    firebase.auth().signOut().then(() => {
      this.setState({loading: false})
      this.props.handleXClick()
    }).catch((error) => {
      this.setState({loading: false, authMessage: error.message})
    });
    }
  


  render() {
    const props = this.props
    let body;
    if (true == false ) {
      body = <CheckoutForm stripe={this.props.stripe} elements={this.props.elements}/>
    }
    else if (this.props.authenticated && this.state.tab != 'passwordChange') {
      body = <div>
        <div id="auth-message">{this.props.user.email}</div>
        {this.state.authMessage == '' ? null : <div id="auth-message">{this.state.authMessage}</div>}
        <div id="reset-password-button" onClick={this.handlePasswordChangeClick}>Change Password</div>
        <a onClick={this.signOut} className="auth-button">{this.state.loading ? <img style={{maxHeight: '100%'}} src="bricks-trans.gif"/> : "Logout"}</a>
      </div>
    } else if (this.state.tab == 'passwordChange' && this.state.secondaryTab == "") {
      body = <div>
        <div id="auth-message">{this.state.authMessage}</div>
        <form className="auth-form" onSubmit={this.handleSubmit}>
          <input className="auth-field" type="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="New Password" />
          <input className="auth-field" type="password" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange} placeholder="Confirm New Password"/>
        </form>
        <a onClick={this.updatePassword} className="auth-button">{this.state.loading ? <img style={{maxHeight: '100%'}} src="bricks-trans.gif"/> : "Submit"}</a>
      </div>
    } else if (this.state.tab == 'login' && this.state.secondaryTab == '') {
      body = 
      <div>
        <div id="auth-message">{(this.state.authMessage == null || this.state.authMessage == "") ? 'CinemaStack is only available to beta testers. Please log in or register to continue.' : this.state.authMessage}</div>
        <form className="auth-form" onSubmit={this.handleSubmit}>
          <input className="auth-field" type="text" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email Address"/>
          <input className="auth-field" type="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password" />
        </form>
        
        <a onClick={this.signIn} className="auth-button">{this.state.loading ? <img style={{maxHeight: '100%'}} src="bricks-trans.gif"/> : "Log In"}</a>
        <div onClick={this.sendPasswordReset} id="forgot-password">Forgot Password</div>
        </div>
    }  else if (this.state.tab == 'register' && this.state.secondaryTab == '') {
      body = 
      <div>
        <div id="auth-message">{this.state.authMessage}</div>
        <form className="auth-form" onSubmit={this.handleSubmit}>
          <input className="auth-field" type="text" value={this.state.email} placeholder={"Email Address"} onChange={this.handleEmailChange}/>
          <input className="auth-field" type="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password" />
          <input className="auth-field" type="password" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange} placeholder="Confirm Password"/>
        </form>
        <a onClick={this.signUp} className="auth-button">{this.state.loading ? <img style={{maxHeight: '100%'}} src="bricks-trans.gif"/> : "Register"}</a>
      </div>
    } else if (this.state.tab == 'register' && !this.state.prepopulatedEmail) {
      body = 
      <div>
        <div id="auth-message">Beta tester registration is limited to crowdfunding backers at this time.</div>
        <a onClick={this.signUp} className="auth-button">Join</a>
      </div>
    }

    return (
      <div id="auth-modal">{
        !this.props.locked ? 
        <div id="close-button-div" onClick={this.props.handleXClick}>
          <a href="#">
            <img id="close-button" src="/close-button.png"/>
          </a>
        </div>
        : null
        }
        { this.props.authenticated? 
        <div id="auth-header">
          <a className="auth-tab-button">Profile</a>
        </div>
        : <div id="auth-header">
          <a href="" className="auth-tab-button" style={{textDecoration: this.state.tab == 'login' ? 'underline' : 'none'}} onClick={this.handleLoginClick}>Log In</a>
          <a href="" className="auth-tab-button" style={{textDecoration: this.state.tab == 'register' ? 'underline' : 'none'}} onClick={this.handleRegisterClick}>Register</a>
        </div>
        }
        {body}
      </div>
    )
  }
}

export default AuthCard