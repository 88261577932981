const customStyles = width => {
  return {
    valueContainer: (provided, state) => ({
      ...provided
      // width: width
    }),
    container: (provided, state) => ({
      ...provided,
      width: width
      // backgroundColor: '#343434',
      // outline: 'none'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#343434',
      borderLeft: '0.00001px solid #343434',
      borderRight: '0.00001px  solid #343434',
      borderTop: '2px solid #696969',
      borderBottom: '0.00001px  solid #343434',
      borderRadius: '11px',
      border: 'none',
      fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px'
    }),
    menuList: (provided, state) => {
    return{ 
      ...provided,
      backgroundColor: '#343434',
      borderLeft: '0.00001px solid #343434',
      borderRight: '0.00001px  solid #343434',
      borderTop: '2px solid #696969',
      borderBottom: '0.00001px  solid #343434',
      borderRadius: '11px',
      fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px',
      display: state.selectProps.isSearchable === false ? '' : state.selectProps.inputValue.length > 2 ? '' : 'none'
    }},
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      // color: state.isFocused && state.isSelected ? 'white' : state.isSelected ? '#33FFFC' : 'white',
      color: state.isFocused && state.isSelected ? '#232323' : state.isFocused && !state.isSelected ? '#232323' : state.isSelected ? '#33FFFC' : 'white',
      // padding: 5,
      backgroundColor: state.isFocused ? '#33FFFC' : '#343434',
      // width: '100'
      fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px'
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderTop: '2px solid #a1a1a1',
        // borderColor: "#33FFFC"
      },
      backgroundColor: '#343434',
      boxShadow: 'none',
      borderLeft: '0.00001px solid #343434',
      borderRight: '0.00001px  solid #343434',
      borderBottom: '0.00001px  solid #343434',
      borderTop: '2px solid #696969',
      borderRadius: '11px',
      fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      // height: '30px',
      // width: '30px',
      color: '#33FFFC',
      // display: state.selectProps.isSearchable ? 'none' : ''
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none'
    }),
    loadingIndicator: (provided, state) => ({
      display: 'none'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 1 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color: '#f4f4f4', fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px' };
    },
    input: (provided, state) => {
    return {
      ...provided,
      color: 'white',
      fontFamily: `'Ubuntu', 'sans-sarif`,
      fontSize: '16px'
    }}
  }
}

export default customStyles