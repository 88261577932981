import React from 'react'

const ScrollArrow = (props) => 
  <div 
    // type='button' 
    // className="scroll-button" 
    id={`scroll-${props.direction}`}
    onClick={props.handleClick}
    style={{
      display: props.hidden && 'none',
      backgroundImage: `url("${props.direction}-arrow-margin-light.png")`,
      backgroundSize: 'cover',
      height: '40px',
      width: '23px',
      opacity: props.pressed ? 0.5 : 1
    }}
  />

export default ScrollArrow