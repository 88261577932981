import React, {Component} from 'react'
import TrackVisibility from 'react-on-screen'
import VizSensor from 'react-visibility-sensor';

class LoadingIndicator extends Component {
	state = {
	    imgViz: false
	 }

	render() {
		return (
			<VizSensor
        		onChange={(isVisible) => {
          			if (isVisible) this.setState({imgViz: true})
        		}}
      		>
      				{ this.state.imgViz ? 
						<img className={`showing-loading-gif`}/>
					:
						<div style={{height: '75px', width: '75px'}} />
      				}
			</VizSensor>
		)
	}
}

export default LoadingIndicator