import bots from '../data/bots.js'

export const clearSeatMaps = () => {
  return {
    type: 'CLEAR_SEAT_MAPS'
  }
}

export const fetchShowings = (location, date) => {
  let { zipcode, city, state } = ''
  if (location.includes(',')){
    city = location.split(',')[0];
    state = location.split(',')[1];
    zipcode = ''
  } else {
    zipcode = location;
    city = ''
    state = ''
  }
  
  const url = `https://us-central1-focused-outlook-225006.cloudfunctions.net/showing?zipcode=${zipcode}&city=${city}&state=${state}&date=${date}`

  return dispatch => {
    dispatch({type: 'MAPS_FETCHED'})
    dispatch({type: 'FETCHING_SHOWINGS'})
    dispatch({type: 'CLEAR_SEAT_MAPS'})
    dispatch({type: 'CHANGE_MOVIE', payload: null})
    dispatch({type: 'CHANGE_THEATER', payload: 'all'})
    return fetch(url)
      .then(res => res.json())
      .then(json => {
        setTimeout(() => {
          dispatch({type: 'STORE_SHOWINGS', payload: json})
          dispatch({type: 'SHOWINGS_FETCHED', payload: json})
        }, 0)
      })
      .catch(err => {
        return dispatch({type: 'SHOWINGS_FETCHED'})
        return fetch(url)
          .then(res => res.json())
          .then(json => {
            dispatch({type: 'STORE_SHOWINGS', payload: json})
            dispatch({type: 'SHOWINGS_FETCHED', payload: json})
          })
          .catch(err => {
            return dispatch({type: 'SHOWINGS_FETCHED'})
          })
      })
  }
}

export const fetchMaps = (showingsByMovie) => {
  let serviceNum = 0

  return async dispatch => {
    dispatch({type: 'FETCHING_MAPS'})
    
    const showings = []
    const showingsByMoviePreserved = showingsByMovie

    let alreadyCatched = false

    fetch('https://us-central1-focused-outlook-225006.cloudfunctions.net/map', {
      method: 'POST', //implementing post as workaround to GET querystring limit
      headers: {
      },
      body: JSON.stringify(showingsByMovie)
    })
      .then(res => {
        return res.json()})
      .then(showings => {
        dispatch({type: 'INSERT_MAPS', payload: showings})
      })
      .catch(() => {
    })
  }
}