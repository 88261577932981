import React from 'react'
import Select from 'react-select'
import selectStyle from '../style/selectStyle'

const DateSelect = (props) =>
  <Select
    placeholder='Date'
    value={dateOptions.find(e => props.date === e.value)}
    options={dateOptions}
    onChange={props.handleDateChange}
    styles={selectStyle(95)}
    isSearchable={false}
  />



const dateOptions = (() => {
  const months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
  const today = new Date()
  let dates = []
  for (let i = 0; i < 3; i += 1) {
    let date = {}
    let d = new Date()
    d.setDate(today.getDate() + i)
    date.value = (d.getYear() + 1900) + '-' + (d.getMonth() + 1 ) + '-' + ((d.getDate() < 10 ? '0' : '') + d.getDate())
    date.label = months[d.getMonth()] + ' ' + d.getDate()
    dates.push(date)
  }
  return dates
})()


export default DateSelect