import React, {Component} from 'react'

class AnimatedSeatMap extends Component {
	constructor(props) {
		super(props)

		let divs = this.props.divs
		const roundedSeatDivs = []
		divs.forEach((div, i) => {
			const roundedDiv = Math.round(div)
			roundedSeatDivs.push(roundedDiv)
			const divTop = div.top.replace('%', '')
			if (divTop < 30 || divTop > 70) {
				div.class = "R"
				divs[i] = div
			}
		})

		this.state = {
			divs,
			roundedSeatDivs,
			xPosition: 50
		}
	}

	componentDidMount() {
		for (let ii = 0; ii < 40; ii++) {
			setTimeout(() => {
				let divs = this.state.divs
				let roundedSeatDivs = this.state.roundedSeatDivs
				for (let i = 0; i < divs.length; i++) {
					let div = divs[i]
					const divLeft = Math.round(div.left.replace('%', ''))
					const roundedDiv = roundedSeatDivs[i]
					if (divLeft == 100 - ii) {
						div.class = "R"
						divs[i] = div
					}
					if (divLeft == 140 - ii) {
						div.class = "R"
						divs[i] = div
					}
				}
				this.setState({divs})
			}, (25 * ii))
		}
	}

	render() {
		const props = this.props
		return (
			<div className='seat-map' style={{height: 300 * props.sizeMultiplier + 'px', width: 300 * props.sizeMultiplier + 'px', transform: `scale(${props.scale})`}}>
      			{this.state.divs.map((div, i) =>
        			<div id={div.id} className={div.class} key={i} style={{left: div.left, top: div.top, height: div.height, width: div.width}}></div>
      			)}
    		</div>
		)
	}
}

export default AnimatedSeatMap