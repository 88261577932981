import React, {Component} from 'react';
import MovieCard from '../components/MovieCard'
import ScrollArrow from '../components/ScrollArrow'
import { connect } from 'react-redux';
import { clearSeatMaps } from '../actions/showings';
import { changeSelectedMovie, filterShowings, clearAllFilters } from '../actions/filters';
import LoadingAnimation from '../components/LoadingAnimation'

class Movies extends Component {
  constructor(props) {
    super(props)

    this.state = { width: 0, height: 0, leftSliderPressed: false, rightSliderPressed: false, demoCompleted: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  handleMovieClick = (selectedMovieId, event) => {
    if (event) {
      event.preventDefault()
    }
    this.setState({demoCompleted: true})
    this.props.changeSelectedMovie(selectedMovieId)
    this.props.clearSeatMaps()
  }

  handleScrollClick = (direction) => {
    if (direction == 'left') {
      this.setState({leftSliderPressed: true})
      setTimeout(() => {this.setState({leftSliderPressed: false})}, 1000 * .25)
    } else {
      this.setState({rightSliderPressed: true})
      setTimeout(() => {this.setState({rightSliderPressed: false})}, 1000 * .25)
    }
    
    for (let i = 0; i < 1000; i += 1) {
      setTimeout(() => {this.refs.scroller.scrollLeft -= direction === 'left' ? 1 : -1} , .25 * i) 
    }
  }

  render() {
    return (
      <div 
        id="movies-browser" 
      >
        <ScrollArrow
          direction='left'
          hidden={this.props.uniqueMovies.length <= 8 ? true : false}
          handleClick={this.handleScrollClick.bind(null, 'left')}
          pressed={this.state.leftSliderPressed}
        />
        <div 
          id="movies-scroller"
          ref="scroller"
          //style={{justifyContent: this.props.uniqueMovies.length <= 8 ? 'center' : ''}}
          style={{justifyContent: (this.props.uniqueMovies.length - 0) * 125 < (this.state.width * .9) ? 'center' : ''}}
        >
          { this.props.uniqueMovies.map((showing, i) =>
            <MovieCard
              key={i}
              i={i}
              showing={showing}
              selectedMovieId={this.props.selectedMovieId}
              handleMovieClick={this.handleMovieClick}
              demoCompleted={this.state.demoCompleted}
            />
            ) 
          }


          { this.props.fetching.fetchingShowings && <LoadingAnimation />}
          { !this.props.fetching.fetchingShowings && this.props.uniqueMovies.length === 0 &&
            <div className="no-movies-available">
              <span style={{color: 'white'}}>No Matching Movies</span>
              <br/>
              <span style={{cursor: 'pointer'}} onClick={() => window.location.href = "/"}>Reset Filters</span>
            </div>
          }
        </div>
        <ScrollArrow
          direction='right'
          hidden={this.props.uniqueMovies.length <= 8 ? true : false}
          handleClick={this.handleScrollClick.bind(null, 'right')}
          pressed={this.state.rightSliderPressed}
        />
      </div>
    )
  }
}

const uniqueMovies = (showings) => {
  let uniqueMovies = [];
  showings.forEach(showing => {
    if (uniqueMovies.every(m => m.movieId != showing.movieId)) {
      let movie = {};
      movie.movieId = showing.movieId;
      movie.movieTitle = showing.movieTitle;
      movie.poster = showing.posterUrl
      uniqueMovies.push(movie)
    }
  });
  return uniqueMovies
}

const mapStateToProps = (state) => {
  return {
    uniqueMovies: uniqueMovies(filterShowings(state.showings, state.filters)),
    fetching: state.fetching,
    selectedMovieId: state.filters.selectedMovieId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedMovie: selectedMovieId => dispatch(changeSelectedMovie(selectedMovieId)),
    clearSeatMaps: () => dispatch(clearSeatMaps()),
    clearAllFilters: () => dispatch(clearAllFilters())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Movies)
