import React from 'react'
import classNames from 'classnames'


class MovieCard extends React.Component {
  componentDidMount() {
    if (this.props.i == 0 && this.props.demoCompleted == false) {
      this.props.handleMovieClick(this.props.showing.movieId)
    }
  }

  render() {
    const props = this.props
    const classes = classNames('movie-card', props.showing.movieId === props.selectedMovieId ? 'selected-movie-card' : '' )

    return (
      <div
        className={classes}
        onClick={props.handleMovieClick.bind(null, props.showing.movieId)}
        // style={{borderColor: props.showing.movieId === props.selectedMovieId ? '#4CCAFA' : '#343434'}}
        style={{cursor: 'pointer'}}
      >  
        <img
          className="movie-poster"
          src={props.showing.poster}
          // src={props.showing.noPoster === true ? "https://storage.googleapis.com/focused-outlook-225006.appspot.com/grey.png" : `${props.showing.poster}`  }
          // onClick={this.props.handleMovieClick.bind(null, movie.id)}
        />
        <div className='movie-title'>
          {props.showing.movieTitle.replace(' (2021)', '')}
        </div>
      </div>
    )
  }
}

export default MovieCard

// const MovieCard = (props) => 
//   <div
//     const btnClass = classNames('btn', 'btn-primary')
//     className='movie-card' 
//     onClick={props.handleMovieClick.bind(null, props.showing.movieId)}
//     // style={{borderColor: props.showing.movieId === props.selectedMovieId ? '#4CCAFA' : '#343434'}}
//     style={{cursor: 'pointer', borderLeft: props.showing.movieId === props.selectedMovieId ? '2px solid #33FFFC' : '0px solid #999',
//       borderTop: props.showing.movieId === props.selectedMovieId ? '2px solid #33FFFC' : '2px solid #999',
//       borderRight: props.showing.movieId === props.selectedMovieId ? '2px solid #33FFFC' : '0px solid #999',
//       borderBottom: props.showing.movieId === props.selectedMovieId ? '2px solid #33FFFC' : '0px solid #999'
//     }}

//   >  
//     <img
//       src={`${props.showing.poster}`}
//       // onClick={this.props.handleMovieClick.bind(null, movie.id)}
//     />
//     <div className='movie-title'>
//       {props.showing.movieTitle.replace(' (2020)', '')}
//     </div>
//   </div>