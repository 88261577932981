import React from 'react'
import Select from 'react-select'
import selectStyle from '../style/selectStyle'

const TimeSelect = props =>
  <Select
    placeholder={props.placeholder}
    value={props.time}
    onChange={props.handleTimeChange}
    options={hourOptions}
    styles={selectStyle(90)}
    isSearchable={false}
  />

const hourOptions = [
  {value: '07:00', label: '7am'},
  {value: '08:00', label: '8am'},
  {value: '09:00', label: '9am'},
  {value: '10:00', label: '10am'},
  {value: '11:00', label: '11am'},
  {value: '12:00', label: 'Noon'},
  {value: '13:00', label: '1pm'},
  {value: '14:00', label: '2pm'},
  {value: '15:00', label: '3pm'},
  {value: '16:00', label: '4pm'},
  {value: '17:00', label: '5pm'},
  {value: '18:00', label: '6pm'},
  {value: '19:00', label: '7pm'},
  {value: '20:00', label: '8pm'},
  {value: '21:00', label: '9pm'},
  {value: '22:00', label: '10pm'},
  {value: '23:00', label: '11pm'},
  {value: '23:59', label: 'Midnite'}
]

export default TimeSelect