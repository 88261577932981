const initialState = {
  location: '',
  date: '2019-11-30',
  theater: 'all',
  reservedSeating: 'true',
  format: 'all',
  selectedMovieId: null,
  sizeMultiplier: -1
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LOCATION':
      return Object.assign({}, state, {location: action.payload})
    case 'CHANGE_DATE':
      return Object.assign({}, state, {date: action.payload})
    case 'CHANGE_THEATER':
      return Object.assign({}, state, {theater: action.payload})
    case 'CHANGE_RESERVED_SEATING':
      return Object.assign({}, state, {reservedSeating: action.payload})
    case 'CHANGE_FORMAT':
      return Object.assign({}, state, {format: action.payload})
    case 'CHANGE_MOVIE':
      return Object.assign({}, state, {selectedMovieId: action.payload})
    case 'CHANGE_MIN_TIME':
      const minDateTime = state.date + '+' + action.payload
      return Object.assign({}, state, {minTime: minDateTime})
    case 'CHANGE_MAX_TIME':
      const maxDateTime = state.date + '+' + action.payload
      return Object.assign({}, state, {maxTime: maxDateTime})
    case 'CHANGE_SIZE_MULTIPLIER':
      return Object.assign({}, state, {sizeMultiplier: action.payload})
    case 'CLEAR_ALL_FILTERS':
      return initialState
    default:
      return state
  }
}

export default filters