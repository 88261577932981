import React, {Component} from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser';
import { filterShowings, changeSizeMultiplier } from '../actions/filters'
import ShowingCard from '../components/ShowingCard'
import Legend from '../components/Legend'
import Slider from 'rc-slider'
import TrackVisibility from 'react-on-screen'
import 'rc-slider/assets/index.css'
import divs from '../data/divs.js'

const animationInterval = 10

class Showings extends Component {

  constructor(props) {
    super(props)

    let divs0 = JSON.parse(JSON.stringify(divs))
    let divs1 = JSON.parse(JSON.stringify(divs))
    let divs2 = JSON.parse(JSON.stringify(divs))

    for (let div of divs0) {
      div.class = "R"
    }
    for (let div of divs2) {
      div.class = "R"
    }

    this.state = {
      divs0,
      divs1,
      divs2,
      demoCompleted: false,
      width: 0, 
      height: 0,
      incrementedViewCount: false,
      visibleSeatMapCount: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.incrementVisibleSeatMaps = this.incrementVisibleSeatMaps.bind(this)
  }

  incrementVisibleSeatMaps() {
    this.setState({visibleSeatMapCount: this.state.visibleSeatMapCount + 1})
  }

  componentDidUpdate(prevProps) {
    if (this.state.demoCompleted == false && this.props.sortedShowings.length != 0) {
      this.setState({demoCompleted: true})
    }

    if (prevProps.sortedShowings[0] && this.props.sortedShowings[0]) {
      if (prevProps.sortedShowings[0].movieTitle != this.props.sortedShowings[0].movieTitle) {
        this.setState({incrementedViewCount: false})
      }
    }

    // const count = this.props.sortedShowings.filter(showing => showing.seatMap != "null").length
    // if (count > 2 && !this.state.incrementedViewCount) {
    //   this.setState({incrementedViewCount: true})
    //   this.props.incrementViewCount()
    // }

    if (this.state.incrementedViewCount == false && this.state.visibleSeatMapCount > 2) {
      this.setState({incrementedViewCount: true})
      this.props.incrementViewCount()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  async componentDidMount() {

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  handleSliderChange = value => {
    this.props.changeSizeMultiplier(value)
  }

  render() {
    const mobile = this.state.width < 768

    let widths = {}
    if (mobile === true) {
      widths["1"] = this.state.width - 10
      widths["2"] = (this.state.width / 2) - 10
      widths["3"] = (this.state.width / 3) - 10
    }

    let sizeMultiplier = 0
    if (this.props.sizeMultiplier == -1 && this.state.width != 0 ) {
      if (mobile == true) {
        this.props.changeSizeMultiplier(2)
      } else {
        this.props.changeSizeMultiplier(1)
      }
    }
    const sortedShowings = this.props.sortedShowings

    return (
      <div id="showings-browser">
        <div id="showings-header-container">
        {
        this.props.sortedShowings.length > 0 ?
          <div 
            id="showings-header"
            style={{justifyContent: 'space-between'}}
            // style={{display: ( this.props.sortedShowings.length === 0 ) && 'none'}}
            // style={{display: ( this.props.fetching.fetchingMaps || this.props.fetching.fetchingShowings || this.props.sortedShowings.length === 0 ) && 'none'}}
            // style={{display: true}}
          >
          <div id="slider">
          <div id="slider-label">Map Size</div>
          <Slider
             onChange={this.handleSliderChange}
             min={mobile ? 1 : .5}
             max={mobile ? 3 : 2}
             step={mobile? 1 : 0.025}
             defaultValue={this.props.sizeMultiplier}
             style={{width: '100px'}}
             trackStyle={{backgroundColor: '#33FFFC'}}
             handleStyle={{borderColor: '#232323', height: "20px", width: "20px", marginTop: "-8px", marginLeft: "-8px"}}
            />
          </div> 
          <Legend />
          </div>

          :
          null
          }
        </div>
      <div id='showings-container'>
        {//this.props.fetching.fetchingMaps ? <img className="loading-gif"/> :
          sortedShowings.map((showing, i) => 
            <TrackVisibility once partialVisibility>
              <ShowingCard
                key={i}
                num={i}
                id={'showing' + 1}
                showing={showing}
                sizeMultiplier={this.props.sizeMultiplier}
                screenWidth={this.state.width - 50}
                mobile={mobile}
                incrementVisibleSeatMaps={this.incrementVisibleSeatMaps}
              />
            </TrackVisibility>
          )
        }
      </div>
    </div>
    )
  }
}

const sortShowings = (showings, filters, selectedMovieId) => {
  const filteredShowings = filterShowings(showings, filters)
  const sortedShowings = filteredShowings.filter(showing =>
    showing.movieId === selectedMovieId
  )
  sortedShowings.sort(function(a,b) {
    const aTime = a.dateTime
    const bTime = b.dateTime
    return (aTime > bTime) ? 1 : ((bTime > aTime) ? -1 : 0);} 
    );
  return sortedShowings
}

const mapStateToProps = state => {
  return {
    sizeMultiplier: state.filters.sizeMultiplier,
    filteredShowings: filterShowings(state.showings, state.filters),
    sortedShowings: sortShowings(state.showings, state.filters, state.filters.selectedMovieId),
    selectedMovieId: state.filters.selectedMovieId,
    fetching: state.fetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeSizeMultiplier: cardSize => dispatch(changeSizeMultiplier(cardSize))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Showings)
