const showings = (state = [], action) => {
  switch (action.type) {
    case 'FETCHING_SHOWINGS':
      return [];
    case 'STORE_SHOWINGS':
      return action.payload;
    case 'INSERT_MAPS':
      return state.map(showing => {
        const newShowing = action.payload.find(e => e.id === showing.id)
        return newShowing ? newShowing : showing
      })
    case 'CLEAR_SEAT_MAPS':
      const newState = state.map(showing => {
        showing.seatMap = "null"
        return showing
      })
      return newState
    case 'FAIL_MAP':
      return state.map(showing => {
        if (showing.id === action.payload) {
          showing.seatMap = "failed#"
        }
        return showing
      })
    default:
      return state
  }
}

export default showings