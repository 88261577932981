import React from 'react'
import Select from 'react-select'
import selectStyle from '../style/selectStyle'

const FormatSelect = props =>
<Select
  placeholder='Format'
  value={props.format}
  onChange={props.handleFormatChange}
  options={formatOptions}
  styles={selectStyle(120)}
  isSearchable={false}
/>

const formatOptions = [
  {value: 'all', label: 'All Formats'},
  {value: 'Standard', label: 'Standard'},
  {value: '3D', label: '3D'},
  {value: '4DX', label: '4DX'},
  {value: 'IMAX', label: 'IMAX'}
]

export default FormatSelect