import React, { Component } from 'react';
import { connect } from 'react-redux'
import Filters from './Filters'
import Movies from './Movies'
import Showings from './Showings'
import AuthCard from '../components/AuthCard'
import { changeLocation, changeDate, changeMovie } from '../actions/filters'
import { fetchShowings, fetchMaps } from '../actions/showings'
import publicIp from "public-ip"
import firebase from 'firebase'
import classNames from 'classnames'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'


const queryString = require('query-string')
var CryptoJS = require("crypto-js");

const stripePromise = loadStripe("pk_test_51JBwezExloM7cdZxRWKAMJbo6kZzmUOYDfCKQWMVEhFAOVhQW1HgZbLRXa9unRq1AfDi2WFS8zFJFeQGLDXG8Ypc00QbqYxM1e");


class App extends Component {
  constructor(props) {
    super(props)

    this.donateRef = React.createRef()

    this.state = {authDisplayed: false, authenticated: false, user: null, dateTracker: "", locationTracker: "", width: null, height: null, donateDisplayed: false, donatedAmount: 0, progress: 5, viewLimitExceeded: false, locked: false, firstMovieAutoDispalyed: false}
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleLogoClick = this.handleLogoClick.bind(this)
    this.handleDonateClick = this.handleDonateClick.bind(this)
    this.handleXClick = this.handleXClick.bind(this)
    this.handleAuthClick = this.handleAuthClick.bind(this)
    this.animateProgress = this.animateProgress.bind(this)
    this.incrementViewCount = this.incrementViewCount.bind(this)
  }

  componentWillMount() {
    const config = {
      apiKey: "AIzaSyAJbAZbnew3dgyL7rX9YgUb3edrygMrpEU",
      authDomain: "cinemastack-firebase-dev.firebaseapp.com",
      projectId: "cinemastack-firebase-dev",
      storageBucket: "cinemastack-firebase-dev.appspot.com",
      messagingSenderId: "310717759198",
      appId: "1:310717759198:web:aa16ded5aac06563ea4c4f",
      measurementId: "G-4QB496G1JP"
    }
    firebase.initializeApp(config)

    this.authListener = this.authListener.bind(this)
    this.authListener()
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    this.props.changeLocation('Los Angeles,ca')
    // fetch('https://ipapi.co/json/')
    //   .then(res => res.json())
    //   .then(json => this.props.changeLocation(`${json.city},${json.region_code}`))

    //   setTimeout(() => {
    //     if(this.props.filters.location.length == 0) {
    //       this.props.changeLocation("Hollywood,CA")
    //     }
    //   }, 1000 )

    const d = new Date()
    const date = (d.getYear() + 1900) + '-' + (d.getMonth() + 1 ) + '-' + ((d.getDate() < 10 ? '0' : '') + d.getDate())
    this.props.changeDate(date)
  }

  componentDidUpdate(prevProps) {
    const { location, date, selectedMovieId } = this.props.filters

    if (prevProps.filters.selectedMovieId == null && selectedMovieId !== prevProps.filters.selectedMovieId && this.state.progress === 5) {
      this.animateProgress()
    }

    // if (prevProps.filters.location !== location || prevProps.filters.date !== date) {
      if (this.props.filters.location.length > 0 && (location.toLowerCase() !== this.state.locationTracker.toLowerCase()  || date.toLowerCase()  !== this.state.dateTracker.toLowerCase() )) {
        this.setState({locationTracker: location, dateTracker: date})
          this.props.fetchShowings(location, date)
      }
    // }
    // const selectedMovieId = this.props.filters.selectedMovieId
    if (prevProps.filters.selectedMovieId != selectedMovieId) {
      let showingsByMovie = this.props.showings.filter(showing => 
        showing.movieId === selectedMovieId && showing.reservedSeating === true
      )
      // this.props.fetchMaps(showingsByMovie)
    }

    if (!this.state.locked && this.state.viewLimitExceeded && !this.state.authenticated) {
      this.setState({locked: true, authDisplayed: true})
    }

    if (this.state.locked && this.state.authenticated) {
      this.setState({locked: false})
    }
  }

  authListener() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({authenticated: true, user: user})
      } else {
        this.setState({authenticated: false})
      }
    })
  }

  async getViewCount() {
    const clientIP = await publicIp.v4({
      fallbackUrls: [ "https://ifconfig.co/ip" ]
    })

    var db = firebase.firestore()

    var docRef = db.collection("ips").doc(clientIP);

    docRef.get().then((doc) => {
    if (doc.exists) {
        if (doc.data().count > 1000000) {
          this.setState({viewLimitExceeded: true})
        }
    } else {
        db.collection("ips").doc(clientIP).set({
          count: "0",
        })
        .then(() => {
        })
    }
    }).catch((error) => {

    })

  }

  async incrementViewCount() {
    var localCount = localStorage.getItem('viewCount') || 0
    localCount++
    localStorage.setItem('viewCount', localCount)
    if (localCount > 1000000) {
      this.setState({viewLimitExceeded: true})
    }

    const clientIP = await publicIp.v4({
      fallbackUrls: [ "https://ifconfig.co/ip" ]
    })

    var db = firebase.firestore()
    var docRef = db.collection("ips").doc(clientIP);

    docRef.get().then((doc) => {
    if (doc.exists) {
        const newValue = parseInt(doc.data().count) + 1
        if (newValue > 1000000) {
          this.setState({viewLimitExceeded: true})
        }
        db.collection("ips").doc(clientIP).set({
          count: newValue,
        })
        .then(() => {
        })
    } else {
        db.collection("ips").doc(clientIP).set({
          count: "1",
        })
        .then(() => {
        })
    }
    }).catch((error) => {

    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  handleLogoClick() {
    if (this.state.donateDisplayed == true ) {
      this.setState({donateDisplayed: false})
      this.setState({progress: 5})
    } else {
      window.location.href = "/"
    }
  }

  handleDonateClick() {
    if (this.state.donateDisplayed == false ) {
      this.setState({progress: 5})
      this.setState({donateDisplayed: true})
      this.animateProgress()
       window.scrollTo(0, 0)
    }
  }

  handleXClick() {
    this.setState({authDisplayed: false})
  }

  handleAuthClick() {
    this.setState({authDisplayed: true})
  }

  handleFeedbackClick() {

  }

  handleContactClick() {

  }

  animateProgress() {
    for (let i = 5; i < 26; i++) {
      setTimeout(() => {
        this.setState({progress: i})
      }, (i * 50) - (50 * 5))
    }
  }

  scrollToDonateRef = () => window.scrollTo(0, this.donateRef.current.offsetTop)

  render() {
    const mobile = this.state.width < 768 && this.state.width !== 0
    var display;
    if (this.state.width < 768 && this.state.width !== 0) {
      display = "mobile"
    } else if (this.state.width >= 768 && this.state.width <= 1024 && this.state.width !== 0) {
      display = "tablet"
    } else {
      display = "web"
    }

    return (
      <Elements stripe={stripePromise}>
      <Router>
      <Switch> 
      <Route
        path="/"
        render={(props) => {
        var prepopulatedEmail;
        if (queryString.parse(props.location.search).ppem) {
          prepopulatedEmail = queryString.parse(props.location.search).ppem
          prepopulatedEmail = prepopulatedEmail.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
          var bytes  = CryptoJS.AES.decrypt(prepopulatedEmail, process.env.REACT_APP_PPEM_KEY)
          prepopulatedEmail = bytes.toString(CryptoJS.enc.Utf8)
        }
        return (
      <div id="app-wrapper">
        {this.state.authDisplayed ? <AuthCard stripe={stripePromise} locked={this.state.locked} authenticated={this.state.authenticated} user={this.state.user} handleXClick={this.handleXClick}/> : null } 
        { this.state.donateDisplayed === false ?
        <div className={this.state.locked ? classNames("app", "blurred") : "app"}>

          { this.props.filters.selectedMovieId ? 
            <div id="top-banner" onClick={() => this.handleDonateClick()} style={{cursor: 'pointer', display: 'none'}}>
              <div id="progress-container-banner">
                <div className="donation-label-banner">$36,543</div>
                <div className="meter-banner">
                  <span style={{width: `${this.state.progress}%`}}></span>
                </div>
              <div className="donation-label-banner">$50,000</div>
            </div>
              <span id="banner-message">Donate to keep this COVID-19 inspired project online.</span>
            </div>
            : null
          }
          <Filters
            handleDonateClick={this.handleDonateClick}
            handleAuthClick={this.handleAuthClick}
            display={display}
          />
          <Movies
          />
          <Showings
            incrementViewCount={this.incrementViewCount}
          />
        </div>
        :
        <div className="app" style={{display: 'flex', flexDirection: 'column'}}>
          <div id='logo-container' onClick={() => window.location.href = "/"}>
          <img id='logo' src='/seats-light-cropped.png'/>
          <h2>CinemaStack</h2>
          </div>
          <h3 id="call-to-action">CinemaStack was inspired by COVID-19 and our wish to return to the movies safely. We are a small startup with no revenue, and we need the public's help to keep this beta online and add new features.</h3>
          
          <div id="progress-container">
            <div id="progress-label-container">
              <h2>$18,365</h2>
              <h2>$50,000</h2>
            </div>
            <div className="meter">
              <span style={{width: `${this.state.progress}%`}}></span>
            </div>
            <div id="donate-button-container">
              <h2 onClick={this.scrollToDonateRef} style={{cursor: 'pointer', color: "#33FFFC"}}>Donate</h2>
            </div>
          </div>

          <div id="future-features">

            <div className="future-feature-row">
              <img id="credit-card-image" src="cardCardBlue.png" />
              <div>
              <div className="future-feature-title">Ticket Sales</div>
              {!mobile && <div className="future-feature-description">CinemaStack does not generate any revenue from ticket sales, we only link to third-party sellers. We are working to provide direct ticket sales to our users. This requires significant investment and development.</div>}
              </div>
            </div>
            { mobile &&
              <div className="future-feature-row-mobile">
                <div className="future-feature-description">CinemaStack does not generate any revenue from ticket sales, we only link to third-party sellers. We are working to provide direct ticket sales to our users. This requires significant investment and development.</div>
              </div>
            }

            <div className="future-feature-row">
              <div>
                <div style={{textAlign: 'right'}} className="future-feature-title">Server Power
                </div>
              {!mobile && <div style={{textAlign: 'right', marginRight: '30px'}} className="future-feature-description">CinemaStack requires massive computing power to quickly look up available seats for a large number of showtimes. Your contribution will keep this service online and help increase processing speeds.</div>}
              </div>
              <img id="server-image" src="serverBlue.png" />
            </div>
            { mobile &&
              <div className="future-feature-row-mobile">
                <div className="future-feature-description">CinemaStack requires massive computing power to quickly look up available seats for a large number of showtimes. Your contribution will keep this service online and help increase processing speeds.</div>
              </div>
            }


            <div className="future-feature-row" ref={this.donateRef}>
              <img id="dev-image" src="devBlue.png" />
              <div>
              <div className="future-feature-title">Human Power</div>
              {!mobile && <div className="future-feature-description">CinemaStack began as a labor of love during the COVID-19 lockdown, but has grown into a full-time job. Your genorousity will ensure that our team can continue to dedicate all our time to this project. </div>}
              </div>
            </div>
            { mobile &&
              <div className="future-feature-row-mobile">
                <div className="future-feature-description">CinemaStack began as a labor of love during the COVID-19 lockdown, but has grown into a full-time job. Your genorousity will ensure that our team can continue to dedicate all our time to this project.</div>
              </div>
            }


          </div>
        </div>
      }
        <div id='footer'>
         <div id='bottom-links-container'>
          <h3 onClick={this.handleDonateClick} style={{display: 'none', cursor: 'pointer', color: "#33FFFC"}}>Donate</h3>
          <a href={"mailto:admin@cinemastack.com"}><h3 style={{cursor: 'pointer', color: "#33FFFC"}}>Contact</h3></a>
          <a href={"mailto:admin@cinemastack.com"}><h3 style={{cursor: 'pointer', color: "#33FFFC"}}>Feedback</h3></a>
        </div>
        <div id="copyright">© Unstacked Technologies LLC 2021</div>
        </div>
      </div>
      )}}/>
      </Switch>
      </Router>
      </Elements>
    );
  }
}

const filterShowings = (showings, filters) => {
  let filteredShowings = Object.assign([], showings)
  if (filters.theater !== 'all') {
    filteredShowings = filteredShowings.filter(showing => filters.theater === showing.theaterName)
  }
  if (filters.reservedSeating === 'true') {
    filteredShowings = filteredShowings.filter(showing => showing.reservedSeating === true)
  }
  if (filters.format !== 'all') {
    filteredShowings = filteredShowings.filter(showing => showing.format.includes(filters.format))
  }
  return filteredShowings
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    showings: state.showings,
    filteredShowings: filterShowings(state.showings, state.filters)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDate: date => dispatch(changeDate(date)),
    changeLocation: location => dispatch(changeLocation(location)),
    fetchShowings: (location, date) => dispatch(fetchShowings(location, date)),
    fetchMaps: showingsByMovie => dispatch(fetchMaps(showingsByMovie))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
