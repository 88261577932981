import React from 'react'
import Select from 'react-select'
import selectStyle from '../style/selectStyle'

const TheaterSelect = props =>
  <Select
    placeholder='Theater'
    options={theaterOptions(props.showings)}
    value={theaterOptions(props.showings).find(e => props.theater === e.value)}
    onChange={props.handleTheaterChange}
    styles={selectStyle(175)}
    isSearchable={false}
  />

const theaterOptions = (showings) => {
  let uniqueTheaterNames = [];
  showings.forEach(showing => {
    if (!uniqueTheaterNames.includes(showing.theaterName)) uniqueTheaterNames.push(showing.theaterName)
  })
  return [{value: 'all', label: 'All Theaters'}]
    .concat(uniqueTheaterNames.map(t => { return { value: t, label: t } }
    )
  )
}

export default TheaterSelect